import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import TextArticleCard from '@components/TextArticleCard';
import Seo from '@core/Seo';
import Navigation from '@core/Navigation';
import mq from '@mq';
import Sort from '@components/Sort';
import useFilter from '@hooks/useFilter';

const sortProps = [
  {
    name: 'date',
    label: 'Datum',
  },
  {
    name: 'sortAuthor',
    label: 'Autor',
  },
  {
    name: 'source',
    label: 'Medium',
  },
];

const TextArticlesPage = ({
  data: {
    allContentfulTextArticle: { nodes: unsortedTexts },
  },
}) => {
  const {
    sortProp,
    setSortProp,
    descending,
    toggleDirection,
    items: texts,
  } = useFilter(unsortedTexts, 'date');

  return (
    <>
      <Seo pageTitle="Texte" />
      <Navigation />
      <main>
        <SortContainer>
          <Sort
            sortProps={sortProps}
            setSortProp={setSortProp}
            currentSortProp={sortProp}
            descending={descending}
            toggleDirection={toggleDirection}
          />
        </SortContainer>
        <List>
          {texts.map(text => (
            <li key={text.id}>
              <TextArticleCard {...text} />
            </li>
          ))}
        </List>
      </main>
    </>
  );
};

const List = styled.ul`
  display: grid;
  row-gap: 3.5rem;

  ${mq.medium} {
    grid-template-columns: 1fr 1fr;
    row-gap: 3.5rem;
    column-gap: 3.5rem;
  }
`;

const SortContainer = styled.div`
  ${mq.medium} {
    display: flex;
    justify-content: flex-end;
  }
`;

export const textArticlesPageQuery = graphql`
  query textArticlesPageQuery {
    allContentfulTextArticle(sort: { fields: date, order: ASC }) {
      nodes {
        id
        author
        sortAuthor
        title
        source
        slug
        externalLink
        isodate: date
        date(formatString: "D.M.YYYY")
      }
    }
  }
`;

export default TextArticlesPage;
