import React from 'react';
import styled from 'styled-components';

const ExternalLink = ({ children, title, href, className }) => (
  <a href={href} title={title} target="_blank" rel="noopener noreferrer" className={className}>
    {children}
  </a>
);

export default ExternalLink;
