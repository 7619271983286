import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import { linkHover } from '@mixins';
import UnstyledExternalLink from '@core/ExternalLink';

const ExternalLink = styled(UnstyledExternalLink)`
  display: grid;
  row-gap: 0.5rem;

  ${linkHover};
`;

const Container = styled(GatsbyLink)`
  display: grid;
  row-gap: 0.5rem;

  ${linkHover};
`;

const TextArticleCard = ({ author, title, source, date, slug, externalLink }) => (
  <Container
    to={!externalLink && slug}
    as={externalLink && ExternalLink}
    href={externalLink && externalLink}
    title={externalLink && title}
    state={{
      modal: true,
    }}
  >
    <div>{author}</div>
    <h3>{title}</h3>
    <div>
      {source}, {date} {externalLink && `→`}
    </div>
  </Container>
);

export default TextArticleCard;
